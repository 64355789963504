@import '~antd/dist/antd.less';
@import '~antd/es/style/themes/default.less';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
.root-spin {
  height: 100%;
  width: 100%;

  div:first-child {
    .ant-spin-spinning {
      max-height: unset;
    }
  }

  .ant-spin-container {
    height: 100%;
    width: 100%;
  }

  .ant-spin-blur {
    opacity: 0;
  }
}

.root-spin-delay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000;

  > div > .ant-spin {
    height: 100vh;
    width: 100vw;
    max-height: 100%;
    background: #fff;
  }
}

.root-spin-delay.d-none {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  width: 100%;

  .ant-input[disabled] {
    color: #000000;
  }
}

.ant-select-item-option-content{
  display: flex;
  align-items: center;
  .label{
    color: #2475b4;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
  }
}

